<template>
    <div v-loading="showLoading" element-loading-text="拼命加载中" style="height:65vh">
        <vue-office-docx v-if="fileType == 'word'" :src="fileUrl" @rendered="rendered" @error="errorHandler" />
        <vue-office-pdf v-else-if="fileType == 'pdf'" :src="fileUrl" @rendered="rendered" @error="errorHandler" />
        <vue-office-excel v-else-if="fileType == 'excel'" :src="fileUrl" @rendered="rendered" @error="errorHandler" />
    </div>
</template>
  
<script>
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf'
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/docx/lib/index.css'
import request from '@/api/request.js'

export default {
    components: {
        VueOfficeDocx, VueOfficePdf, VueOfficeExcel
    },
    data() {
        return {
            fileType: 'word',
            fileUrl: 'http://static.shanhuxueyuan.com/test6.docx', //设置文档网络地址，可以是相对地址
            showLoading: true,
        }
    },
    mounted() {
        this.parkDetails(this.$route.query.id)
    },
    methods: {
        rendered() {
            console.log("渲染完成")
            this.showLoading = false
        },
        errorHandler() {
            console.log("渲染失败")
            this.showLoading = false
        },
        parkDetails(id) {
            request.parkDetails(id).then((res) => {
                if (res.data.data != null) {
                    let valDetails = res.data.data;
                    if (valDetails.xgwj) {
                        if (valDetails.xgwj.includes(".doc")) {
                            this.fileType = 'word'
                        } else if (valDetails.xgwj.includes(".pdf")) {
                            this.fileType = 'pdf'
                        } else {
                            this.fileType = 'excel'
                        }
                        this.fileUrl = valDetails.xgwj
                    }
                }
            })
        },
    }
}
</script>